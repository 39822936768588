import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { formatUnits } from "@ethersproject/units";
import styled from "styled-components";
import merkleProof from "./merkle-proof.json";
import merkleDistributorAbi from "./abi/MerkleDistributorWithRecover.json";
import { Connection } from "./Connection";
import logo from "./images/dfx-logo-combo-horizontal-light.png";
import metamask from "./images/metamask-fox.svg";
import walletconnect from "./images/walletconnect-logo.png";
import { MagicButton } from "./MagicButton";
import coin from "./images/coin.jpg";

const xs = "650px";

const Wrapper = styled.div`
  display: flex;
  background: var(--neutral-color);
  height: 100vh;

  @media (max-width: ${xs}) {
    flex-direction: column;
  }
`;

const ClaimWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 2rem;
  flex: 2;
`;

const Pattern = styled.div`
  flex: 1;
  background-image: url(${coin});

  @media (max-width: ${xs}) {
    flex: 0.3;
  }
`;

const Logo = styled.img`
  width: 15rem;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: ${xs}) {
    width: 100%;
  }
`;

const ConnectButtons = styled.div`
  display: flex;
  flex-flow: wrap;

  @media (max-width: ${xs}) {
    flex-direction: column;
    width: 100%;
  }
`;

const Value = styled.div`
  font-family: var(--value-font);
`;

export const Button = styled.button`
  padding: 8px 16px;
  border-radius: var(--rounded-box-border-radius);
  color: var(--primary-text-light);
  opacity: 1;
  cursor: pointer;

  &:disabled {
    opacity: 0.25;
    cursor: not-allowed;
  }
`;

const PrimaryButton = styled(Button)`
  border: 2px solid var(--primary-color);
  background: var(--primary-color);

  &:hover:not(:disabled) {
    background: var(--primary-hover-color);
    border: 2px solid var(--primary-hover-color);

    > * {
      opacity: 0.5;
    }
  }

  @media (max-width: ${xs}) {
    width: 100%;
  }
`;

// https://codemyui.com/animated-gradient-button-hover-effect/
const FallbackMagicButton = styled(Button)`
  background-image: linear-gradient(
    to right,
    #00e8f1 0%,
    #227df5 33%,
    #eb00d2 66%,
    #7f31f0 100%
  );
  ${(props: any) =>
    !props.disabled &&
    `
    background-size: 400% 400%;
    animation: gradient 7s ease infinite;
    @keyframes gradient {
      50% {
        background-position: 100% 0;
      }
    }
  `}

  width: 20rem;

  @media (max-width: ${xs}) {
    width: 100%;
  }
`;

const StyledMagicButton = styled(MagicButton)`
  width: 20rem;

  @media (max-width: ${xs}) {
    width: 100%;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonImg = styled.img`
  width: 2rem;
`;

const Spacer = styled.div`
  height: 1rem;
`;

const merkleProofClaims = Object.keys(merkleProof.claims).reduce((acc, x) => {
  acc[x.toLowerCase()] = merkleProof.claims[x];
  return acc;
}, {});
const merkleDistributorAddress = "0x0d3Ea9FAa63Ee87D6Eb901759c18fA0a10c37F35";

const TxStatus = {
  Approving: "Approving",
  Executing: "Executing",
  Failed: "Failed",
  Success: "Success",
};

const ClaimStatus = {
  Claimed: "Claimed",
  Unclaimed: "Unclaimed",
  Unclaimable: "Unclaimable",
};

function App() {
  const {
    connectToMetaMask,
    connectToWalletConnect,
    account: address,
    signer,
  } = Connection.useContainer();

  const [contract, setContract] = useState(null);
  const [txStatus, setTxStatus] = useState(null);
  const [claimStatus, setClaimStatus] = useState(null);

  useEffect(() => {
    const init = async () => {
      if (signer && address) {
        const contract_ = new ethers.Contract(
          merkleDistributorAddress,
          merkleDistributorAbi,
          signer
        );

        setContract(contract_);

        const claim = merkleProofClaims[address.toLowerCase()];
        if (claim) {
          const isClaimed = await contract_.isClaimed(claim.index);
          setClaimStatus(
            isClaimed ? ClaimStatus.Claimed : ClaimStatus.Unclaimed
          );
        } else {
          setClaimStatus(ClaimStatus.Unclaimable);
        }
      }
    };

    init();
  }, [signer, address]);

  const claimBal = async () => {
    try {
      setTxStatus(TxStatus.Executing);

      const { index, amount, proof } = merkleProofClaims[address.toLowerCase()];

      const tx = await contract.claim(index, address, amount, proof);
      await tx.wait();

      const isClaimed = await contract.isClaimed(index);
      setClaimStatus(isClaimed ? ClaimStatus.Claimed : ClaimStatus.Unclaimed);

      setTxStatus(TxStatus.Success);
    } catch (e) {
      setTxStatus(TxStatus.Failed);
    }
  };

  let claimable = ethers.constants.Zero;
  if (address && merkleProofClaims[address.toLowerCase()]) {
    claimable = ethers.BigNumber.from(
      merkleProofClaims[address.toLowerCase()].amount
    );
  }

  const isClaimBtnDisabled =
    !contract || claimable.lte(ethers.constants.Zero || claimStatus === null);
  let ClaimButton = FallbackMagicButton;
  if (!isClaimBtnDisabled) {
    // Only works in Chrome
    if (CSS.registerProperty) {
      ClaimButton = StyledMagicButton;
    }
  }

  return (
    <Wrapper>
      <Pattern />

      <ClaimWrapper>
        <Logo src={logo} alt="DFX logo" />
        <Spacer />
        <h1>Claim your Balancer fees from the DFX v0 pools.</h1>
        <Spacer />
        <Spacer />

        <Section>
          <h3>1. Connect a wallet</h3>
          <Spacer />
          {address ? (
            <Value>{address}</Value>
          ) : (
            <ConnectButtons>
              <PrimaryButton
                onClick={connectToMetaMask}
                style={{ marginRight: "1rem" }}
              >
                <ButtonContent>
                  <span style={{ marginRight: "1rem" }}>Metamask</span>{" "}
                  <ButtonImg src={metamask} alt="metamask fox" />
                </ButtonContent>
              </PrimaryButton>
              <Spacer />
              <PrimaryButton onClick={connectToWalletConnect}>
                <ButtonContent>
                  <span style={{ marginRight: "1rem" }}>WalletConnect</span>{" "}
                  <ButtonImg src={walletconnect} alt="walletconnect logo" />
                </ButtonContent>
              </PrimaryButton>
            </ConnectButtons>
          )}
        </Section>

        <Spacer />
        <Spacer />

        <Section>
          <h3>2. Claim your rewards!</h3>
          <Spacer />
          {address && (
            <>
              <Value style={{ fontSize: "2rem" }}>
                {formatUnits(claimable)} BAL
              </Value>
              <Spacer />
            </>
          )}

          {claimStatus === null && address && (
            <div>Checking claim status...</div>
          )}

          {claimStatus === ClaimStatus.Claimed && (
            <div>Success! You have claimed your BAL tokens.</div>
          )}
          {claimStatus === ClaimStatus.Unclaimable && (
            <div>You have no BAL tokens to claim.</div>
          )}
          {(address === null || claimStatus === ClaimStatus.Unclaimed) && (
            <ClaimButton
              onClick={claimBal}
              style={{ border: "none", fontSize: "2rem", minWidth: "100%" }}
              disabled={isClaimBtnDisabled}
            >
              {txStatus !== TxStatus.Executing && "Claim"}
              {txStatus === TxStatus.Executing && "Claiming..."}
            </ClaimButton>
          )}
        </Section>
      </ClaimWrapper>
    </Wrapper>
  );
}

export default App;
