import { useEffect, useState } from "react";
import { createContainer } from "unstated-next";
import { ethers } from "ethers";
import WalletConnectProvider from "@walletconnect/web3-provider";

type Provider = ethers.providers.Web3Provider;
type Signer = ethers.Signer;

const useConnection = () => {
  const [provider, setProvider] = useState<Provider | null>(null);
  const [signer, setSigner] = useState<Signer | null>(null);
  const [account, setAccount] = useState<string | null>(null);
  const [blockNum, setBlockNum] = useState<number | null>(null);

  const onLoad = async () => {
    if (!window.ethereum?.request) {
      throw new Error(
        "An up-to-date MetaMask was not found. Visit https://metamask.io/ and install the extension."
      );
    }

    // Force a hard refresh on chain and account change
    /* eslint-disable @typescript-eslint/ban-ts-comment */
    // @ts-ignore
    window.ethereum.on("chainIdChanged", () => window.location.reload());
    // @ts-ignore
    window.ethereum.on("chainChanged", () => window.location.reload());
    // @ts-ignore
    window.ethereum.on("accountsChanged", () => window.location.reload());
    /* eslint-enable @typescript-eslint/ban-ts-comment */

    // check if MetaMask has an approved account for us to connect to
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (accounts.length > 0) {
      // Init
      const provider_ = new ethers.providers.Web3Provider(window.ethereum);
      const signer_ = await provider_.getSigner();

      // Set state
      setProvider(provider_);
      setSigner(signer_);
      setAccount(ethers.utils.getAddress(accounts[0]));
    }
  };

  const connectToMetaMask = async () => {
    if (window.ethereum === undefined) {
      throw new Error(
        "No MetaMask found, visit https://metamask.io/ and install the extension."
      );
    }

    // Init
    const provider_ = new ethers.providers.Web3Provider(window.ethereum);
    // Open metamask window and user will be prompted to connect to the dapp
    await provider_.send("eth_requestAccounts", []);

    const signer_ = await provider_.getSigner();
    const address_ = await signer_.getAddress();

    // Set state
    setProvider(provider_);
    setSigner(signer_);
    setAccount(ethers.utils.getAddress(address_));
  };

  const connectToWalletConnect = async () => {
    const walletConnectProvider = new WalletConnectProvider({
      rpc: {
        // Use this if you want to test against local hardhat mainnet fork
        // 1: "http://localhost:8545"
        // Mainnet
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        1: `https://eth-mainnet.alchemyapi.io/v2/${process.env
          .NEXT_PUBLIC_ALCHEMY_API_KEY!}`,
      },
      bridge: "https://bridge.walletconnect.org",
      qrcode: true,
      pollingInterval: 15000,
    });
    // Enable session (triggers QR Code modal)
    // User scans QR code on their mobile wallet
    // mobile wallet will then be connected to app.
    // If user has already allowed the website, modal will not appear.
    // Clear the website's localstorage to trigger a new modal.
    await walletConnectProvider.enable();

    const provider_ = new ethers.providers.Web3Provider(walletConnectProvider);

    const signer_ = await provider_.getSigner();
    const address_ = await signer_.getAddress();

    // Set state
    setProvider(provider_);
    setSigner(signer_);
    setAccount(ethers.utils.getAddress(address_));
  };

  useEffect(() => {
    onLoad().catch(console.error);
  }, []);

  const WAIT_FOR_BLOCKS = 1;
  useEffect(() => {
    const blockHandler = (newBlockNum: number) => {
      // Only update if new block is the first one we've seen
      // Or is at least N greater than current
      if (blockNum === null || newBlockNum - blockNum >= WAIT_FOR_BLOCKS) {
        setBlockNum(newBlockNum);
      }
    };

    provider?.on("block", blockHandler);

    return () => {
      provider?.off("block", blockHandler);
    };
  }, [provider, blockNum]);

  return {
    provider,
    signer,
    account,
    connectToMetaMask,
    connectToWalletConnect,
    blockNum,
  };
};

export const Connection = createContainer(useConnection);
