// https://www.joshwcomeau.com/react/rainbow-button/#a-vanilla-js-demo
import { FC, useState, useRef, useEffect, CSSProperties } from "react";
import { Button } from "./App";

interface Props {
  intervalDelay?: number;
  style?: CSSProperties;
}

// Sampled from DFX logo
const rainbowColors = [
  "#00e8f1", 
  "#227df5", 
  "#eb00d2",
  "#7f31f0"
];
const paletteSize = rainbowColors.length;

function useIncrementingNumber(delay: number) {
  const [count, setCount] = useState(0);

  const savedCallback = useRef(() => setCount((c) => c + 1));

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);

  return count;
}

const useRainbow = ({ intervalDelay = 2000 }) => {
  // Register all custom properties.
  // This only ever needs to be done once, so there are no dependencies.
  useEffect(() => {
    for (let i = 0; i < 3; i++) {
      try {
        // @ts-ignore
        CSS.registerProperty({
          name: `--magic-rainbow-color-${i}`,
          initialValue: rainbowColors[i],
          syntax: "<color>",
          inherits: false,
        });
      } catch (err) {
        console.log(err);
      }
    }
  }, []);
  // Get an ever-incrementing number from another custom hook*
  const intervalCount = useIncrementingNumber(intervalDelay);
  // Using that interval count, derive each current color value
  return {
    "--magic-rainbow-color-0": rainbowColors[(intervalCount + 1) % paletteSize],
    "--magic-rainbow-color-1": rainbowColors[(intervalCount + 2) % paletteSize],
    "--magic-rainbow-color-2": rainbowColors[(intervalCount + 3) % paletteSize],
  };
};

export const MagicButton: FC<Props> = ({ children, intervalDelay = 1000, style = {}, ...rest }) => {
  // The hook should take 1 argument, `intervalDelay`.
  // it should return an object in this shape:
  /*
    {
      '--magic-rainbow-color-0': hsl(...),
      '--magic-rainbow-color-1': hsl(...),
      '--magic-rainbow-color-2': hsl(...),
    }
  */
  const colors = useRainbow({ intervalDelay });
  const colorKeys = Object.keys(colors);
  return (
    <Button
      style={{
        // Spread the colors to define them as custom properties
        // on this element
        ...colors,
        // Use the keys to set the same transition on all props.
        transition: `
          ${colorKeys[0]} ${intervalDelay}ms linear,
          ${colorKeys[1]} ${intervalDelay}ms linear,
          ${colorKeys[2]} ${intervalDelay}ms linear
        `,
        // Use those property values in our gradient.
        // Values go from 2 to 0 so that colors radiate
        // outwards from the top-left circle, not inwards.
        background: `
          radial-gradient(
            circle at top left,
            var(${colorKeys[2]}),
            var(${colorKeys[1]}),
            var(${colorKeys[0]})
          )
        `,
        ...style
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};
